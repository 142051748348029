import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';

import { ROUTES } from '_constants/routes';
import { SECTION_ID } from '_constants/ui';
import { theme } from '_constants/theme';
import { ICONS_ALIASES } from '_constants/icons';
import { PROCESSING_CODES } from '_constants/processing';
import { PRODUCT_CODES } from '_constants/products';

import Icon from '_components/atomic/Icon';
import Button from '_components/atomic/Button';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow: hidden;

  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.large15X}px;
  padding-bottom: ${theme.spaces.large19X}px;

  background-color: #fafafa;

  @media ${theme.devices.tablet} {
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
    padding-top: ${theme.spaces.large6X}px;
    padding-bottom: ${theme.spaces.large8X}px;
  }
`;

const StyledLabel = styled.div`
  padding-left: ${theme.spaces.regular - 2}px;
  padding-right: ${theme.spaces.regular - 2}px;
  padding-top: ${theme.spaces.smallest}px;
  padding-bottom: ${theme.spaces.smallest}px;

  border-radius: 66px;
  background: #f9f5ff;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};
`;

const StyledTitle = styled.div`
  padding-top: ${theme.spaces.medium}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large8X}px;
  font-weight: 600;
  line-height: 39.6px;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.large4X}px;
    line-height: 30.8px;
    text-align: center;
  }
`;

const StyledSubtitle = styled.div`
  padding-top: ${theme.spaces.medium}px;
  margin-bottom: ${theme.spaces.large6X + 2}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large2X}px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    margin-bottom: ${theme.spaces.large2X + 2}px;
    font-size: ${theme.fontSizes.regular}px;
    text-align: center;
  }
`;

const StyledCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: ${theme.spaces.largeX + 2}px;
  width: 100%;

  @media ${theme.devices.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spaces.large}px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 25.6944444%;
  position: relative;

  background: ${theme.colors.white};
  border: 1px solid #e0d4fe;
  border-radius: 10px;

  &:nth-child(even) {
    box-shadow: 0px 1px 2px 0px #1018280d, 0px 30px 70px 0px #6941c64d;
  }

  @media ${theme.devices.tablet} {
    min-height: 538px;
    width: 100%;
  }
`;

const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${theme.spaces.medium}px;
`;

const StyledCardFooter = styled.div`
  padding: ${theme.spaces.medium}px;
  background: #fafafa;
  border-top: 1px solid #e0d4fe;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  button {
    width: 100%;
    justify-content: center;
  }
`;

const StyledCardLabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${theme.spaces.smallX}px;
  margin-bottom: ${theme.spaces.small + 2}px;
`;

const StyledCardPrice = styled.div`
  margin-bottom: ${theme.spaces.medium}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large13X}px;
  font-weight: 600;
  line-height: 61.6px;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  span {
    font-size: ${theme.fontSizes.large}px;
    line-height: 22px;
    background: ${theme.colors.gray.secondary};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const StyledCardTitle = styled.div`
  margin-bottom: ${theme.spaces.medium}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.medium}px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.secondary};
`;

const StyledCardText = styled.div`
  margin-bottom: ${theme.spaces.medium}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.secondary};

  span {
    text-decoration: underline;
  }
`;

const StyledCardItemContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const StyledCardItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spaces.medium}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.primary};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledIconContainer = styled.span`
  display: inline-flex;
  margin-right: ${theme.spaces.smallX}px;
`;

const SolutionsSection = ({ prices = {} }) => {
  const monthlyBookkeepingPrice =
    prices[PRODUCT_CODES.accountingMonthlyBookkeeping] || 0;
  const cCorpTaxFilingPrice =
    prices[PRODUCT_CODES.accountingCCorpTaxFiling] || 0;
  const sCorpTaxFilingPrice =
    prices[PRODUCT_CODES.accountingSCorpTaxFiling] || 0;
  const partnershipTaxFilingPrice =
    prices[PRODUCT_CODES.accountingPartnershipTaxFiling] || 0;
  const personalTaxFilingPrice =
    prices[PRODUCT_CODES.accountingPersonalTaxFiling] || 0;

  return (
    <StyledOuterContainer id={SECTION_ID.solutions}>
      <StyledInnerContainer>
        <StyledLabel>Pricing</StyledLabel>
        <StyledTitle>
          Keep it simple with our combined Bookkeeping and Tax filing packages
        </StyledTitle>
        <StyledSubtitle>
          Choose the ideal solution for your business
        </StyledSubtitle>
        <StyledCardContainer>
          <StyledCard>
            <StyledCardHeader>
              <StyledCardLabelContainer>
                <StyledLabel>Package 1</StyledLabel>
              </StyledCardLabelContainer>
              <StyledCardTitle>
                <b>LLC</b> Tax Bundle
              </StyledCardTitle>
              <StyledCardPrice>
                ${prices[PROCESSING_CODES.llcTaxBundle]?.toFixed(0)}
                <span>/month</span>
              </StyledCardPrice>
              <StyledCardText>
                Bookkeeping & Tax Filing bundle for <span>LLC</span> Owners
              </StyledCardText>
              <StyledCardItemContainer>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Monthly Bookkeeping</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Schedule C Tax Filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Personal Tax Filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Accurate and timely tax filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Maximized deductions and credits</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Dedicated Accountant & Bookkeeper</span>
                </StyledCardItem>
              </StyledCardItemContainer>
            </StyledCardHeader>
            <StyledCardFooter>
              <Link href={ROUTES.optInGeneral}>
                <Button>Learn more</Button>
              </Link>
            </StyledCardFooter>
          </StyledCard>
          <StyledCard>
            <StyledCardHeader>
              <StyledCardLabelContainer>
                <StyledLabel>Package 2</StyledLabel>
                <StyledLabel>+$99 for every 50k / rev.</StyledLabel>
              </StyledCardLabelContainer>
              <StyledCardTitle>
                <b>S - Corp</b> Tax Bundle
              </StyledCardTitle>
              <StyledCardPrice>
                ${prices[PROCESSING_CODES.sCorpTaxBundle]?.toFixed(0)}
                <span>/month</span>
              </StyledCardPrice>
              <StyledCardText>
                Bookkeeping & Tax Filing bundle for <span>S - Corp</span> Owners
              </StyledCardText>
              <StyledCardItemContainer>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Monthly Bookkeeping</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>S Corp Tax Filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Personal Tax Filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Accurate and timely tax filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Maximized deductions and credits</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Dedicated Accountant & Bookkeeper</span>
                </StyledCardItem>
              </StyledCardItemContainer>
            </StyledCardHeader>
            <StyledCardFooter>
              <Link href={ROUTES.optInGeneral}>
                <Button>Learn more</Button>
              </Link>
            </StyledCardFooter>
          </StyledCard>
          <StyledCard>
            <StyledCardHeader>
              <StyledCardLabelContainer>
                <StyledLabel>Package 3</StyledLabel>
              </StyledCardLabelContainer>
              <StyledCardTitle>
                <b>Partnership</b> Tax Bundle
              </StyledCardTitle>
              <StyledCardPrice>
                ${prices[PROCESSING_CODES.partnershipTaxBundle]?.toFixed(0)}
                <span>/month</span>
              </StyledCardPrice>
              <StyledCardText>
                Bookkeeping & Tax Filing bundle for business&nbsp;
                <span>Partnerships</span>
              </StyledCardText>
              <StyledCardItemContainer>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Monthly Bookkeeping</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Partnership Tax Filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Personal Tax Filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Accurate and timely tax filing</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Maximized deductions and credits</span>
                </StyledCardItem>
                <StyledCardItem>
                  <StyledIconContainer>
                    <Icon
                      alias={ICONS_ALIASES.check}
                      color={theme.colors.indigo.primary}
                    />
                  </StyledIconContainer>
                  <span>Dedicated Accountant & Bookkeeper</span>
                </StyledCardItem>
              </StyledCardItemContainer>
            </StyledCardHeader>
            <StyledCardFooter>
              <Link href={ROUTES.optInGeneral}>
                <Button>Learn more</Button>
              </Link>
            </StyledCardFooter>
          </StyledCard>
        </StyledCardContainer>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

SolutionsSection.propTypes = {
  prices: PropTypes.object,
};

export default SolutionsSection;
