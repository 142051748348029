import { createSelector } from '@reduxjs/toolkit';

export const selectPrices = (state) => state.variants.prices.data;
export const selectPricesError = (state) => state.variants.prices.error;
export const selectPricesLoading = (state) => state.variants.prices.loading;

export const selectPriceMapByProductCode = (productCode) =>
  createSelector([selectPrices], (prices) => {
    return prices
      .filter(({ code: { code } }) => code === productCode)
      .reduce((res, { option, price }) => {
        res[option] = price;
        return res;
      }, {});
  });

export const selectPriceMapByProcessingCode = (processingCode) =>
  createSelector([selectPrices], (prices) => {
    return prices
      .filter(({ option }) => option === processingCode)
      .reduce((res, { code: { code }, price }) => {
        res[code] = price;
        return res;
      }, {});
  });
