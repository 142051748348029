import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';

import { config } from '_config';
import { ROUTES } from '_constants/routes';
import { ICONS_ALIASES } from '_constants/icons';
import { theme } from '_constants/theme';

import Icon from '_components/atomic/Icon';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow: hidden;

  width: 100%;

  padding-top: ${theme.spaces.large10X}px;
  padding-bottom: ${theme.spaces.regular}px;
  padding-left: ${theme.spaces.large28X - 1}px;
  padding-right: ${theme.spaces.large28X - 1}px;

  @media ${theme.devices.tablet} {
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledImageContainer = styled(forwardRef(({ desktop, mobile, absolute, top, bottom, left, right, zIndex, ...props }, ref) => <div ref={ref} {...props} />))`
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
    `}

  ${({ top }) =>
    typeof top !== 'undefined' &&
    css`
      top: ${typeof top === 'string' ? top : `${top}px`};
    `}

  ${({ bottom }) =>
    typeof bottom !== 'undefined' &&
    css`
      bottom: ${typeof bottom === 'string' ? bottom : `${bottom}px`};
    `}

  ${({ left }) =>
    typeof left !== 'undefined' &&
    css`
      left: ${typeof left === 'string' ? left : `${left}px`};
    `}

  ${({ right }) =>
    typeof right !== 'undefined' &&
    css`
      right: ${typeof right === 'string' ? right : `${right}px`};
    `}

  ${({ zIndex }) =>
    typeof zIndex !== 'undefined' &&
    css`
      z-index: ${zIndex};
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${theme.devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCircle = styled(forwardRef(({ desktop, mobile, top, bottom, left, right, zIndex, size, ...props }, ref) => <div ref={ref} {...props} />))`
  position: absolute;

  border-radius: 50%;
  box-shadow: 0px 133px 154px 0px #6741c64d, 0px 50px 124px 0px #ff7a0033;

  ${({ size }) =>
    typeof size !== 'undefined' &&
    css`
      height: ${typeof size === 'string' ? size : `${size}px`};
      width: ${typeof size === 'string' ? size : `${size}px`};
    `}

  ${({ top }) =>
    typeof top !== 'undefined' &&
    css`
      top: ${typeof top === 'string' ? top : `${top}px`};
    `}

  ${({ bottom }) =>
    typeof bottom !== 'undefined' &&
    css`
      bottom: ${typeof bottom === 'string' ? bottom : `${bottom}px`};
    `}

  ${({ left }) =>
    typeof left !== 'undefined' &&
    css`
      left: ${typeof left === 'string' ? left : `${left}px`};
    `}

  ${({ right }) =>
    typeof right !== 'undefined' &&
    css`
      right: ${typeof right === 'string' ? right : `${right}px`};
    `}

  ${({ zIndex }) =>
    typeof zIndex !== 'undefined' &&
    css`
      z-index: ${zIndex};
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${theme.devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledContentBox = styled(forwardRef(({ transparent, center, ...props }, ref) => <div ref={ref} {...props} />))`
  display: flex;
  flex-direction: column;

  max-width: 800px;
  width: 100%;
  padding: ${theme.spaces.large2X}px;
  margin-bottom: ${theme.spaces.large2X}px;

  border-radius: 12px;
  background: ${theme.colors.white};
  box-shadow: 0px 10px 90px -12px #b692f64d;
  z-index: 1;

  a {
    text-decoration: none;
  }

  ${({ transparent }) =>
    transparent &&
    css`
      background: transparent;
      box-shadow: none;
    `}

  ${({ center }) =>
    center &&
    css`
      align-items: center;
    `}

  @media ${theme.devices.tablet} {
    padding: ${theme.spaces.regular}px;

    ${({ transparent }) =>
      transparent &&
      css`
        padding: 0;
      `}
  }
`;

const StyledTitle = styled.div`
  margin-bottom: ${theme.spaces.large6X}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large13X}px;
  font-weight: 600;
  line-height: 66px;
  letter-spacing: -0.03em;
  text-align: center;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.large8X}px;
    line-height: 39.6px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledText = styled(forwardRef(({ color, ...props }, ref) => <div ref={ref} {...props} />))`
  font-family: 'Inter';
  font-size: ${theme.fontSizes.medium}px;
  font-weight: 500;
  line-height: 28px;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.smallX}px;
    line-height: 16px;
  }
`;

// eslint-disable-next-line max-len
// eslint-disable-next-line prettier/prettier, react/display-name, @next/next/no-img-element, jsx-a11y/alt-text
const StyledImg = styled(forwardRef(({ height, width, ...props }, ref) => <img ref={ref} {...props} />))`
  ${({ height }) =>
    typeof height !== 'undefined' &&
    css`
      height: ${typeof height === 'string' ? height : `${height}px`};
    `}

  ${({ width }) =>
    typeof width !== 'undefined' &&
    css`
      width: ${typeof width === 'string' ? width : `${width}px`};
    `}
`;

const StyledIconContainer = styled.span`
  display: inline-flex;
  padding: ${theme.spaces.small / 2}px;

  border-radius: 60px;
  background: ${theme.colors.white};

  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};

  cursor: pointer;

  span {
    margin-left: ${theme.spaces.small2X}px;
    margin-right: ${theme.spaces.small2X}px;
  }

  @media ${theme.devices.tablet} {
    span {
      display: none;
    }
  }
`;

const StyledTopLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  margin-bottom: ${theme.spaces.largeX}px;
  padding-left: ${theme.spaces.medium}px;
  padding-right: ${theme.spaces.small / 3}px;
  padding-top: ${theme.spaces.small / 3}px;
  padding-bottom: ${theme.spaces.small / 3}px;

  border-radius: 66px;
  background: #f9f5ff;
  box-shadow: -60px -160px 120px 20px #6741c64d;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.medium}px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};

  @media ${theme.devices.tablet} {
    width: 100%;
    gap: 8px;
    font-size: ${theme.fontSizes.small}px;
    line-height: 21px;
  }
`;

const StyledBottomLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 65px;

  margin-bottom: ${theme.spaces.large2X}px;
  padding-left: ${theme.spaces.medium}px;
  padding-right: ${theme.spaces.small - 3}px;
  padding-top: ${theme.spaces.small - 3}px;
  padding-bottom: ${theme.spaces.small - 3}px;

  border: 3px solid #d0d5dd33;
  border-radius: 38px;
  background: ${theme.colors.indigo.primary};
  box-shadow: 0px 0px 0px 4px #f2f4f7, 0px 1px 2px 0px #1018280d,
    0px 20px 40px 0px #6941c64d;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.medium}px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: ${theme.colors.white};

  cursor: pointer;

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.regular}px;
    line-height: 24px;
  }
`;

const StyledBenefitsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${theme.spaces.large18X}px;

  @media ${theme.devices.tablet} {
    padding-top: ${theme.spaces.large10X}px;
  }
`;

const StyledBenefitsInnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

const StyledBenefitsLabel = styled.div`
  margin-bottom: ${theme.spaces.medium}px;
  padding-left: ${theme.spaces.regular - 2}px;
  padding-right: ${theme.spaces.regular - 2}px;
  padding-top: ${theme.spaces.smallest}px;
  padding-bottom: ${theme.spaces.smallest}px;

  border-radius: 66px;
  background: #f9f5ff;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};
`;

const StyledBenefitsTitle = styled.div`
  margin-bottom: ${theme.spaces.large6X + 2}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large8X}px;
  font-weight: 600;
  line-height: 39.6px;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.large4X}px;
    line-height: 30.8px;
  }
`;

const StyledBenefitsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-width: 22%;
  padding-right: ${theme.spaces.large5X + 21}px;
  margin-bottom: ${theme.spaces.large6X + 2}px;

  &::before {
    display: block;
    position: absolute;
    content: '';
    left: ${-(theme.spaces.large5X + 1)}px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: ${theme.colors.indigo.primary};
  }

  @media ${theme.devices.tablet} {
    width: 100%;
    padding-left: ${theme.spaces.large5X + 1}px;
    padding-right: ${theme.spaces.large5X + 1}px;

    &::before {
      left: 0;
    }
  }
`;

const StyledBenefitsCardIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spaces.medium}px;
  padding: ${theme.spaces.medium / 2 - 2}px;
  border-radius: 10px;
  border: 1px solid #e0d4fe;
  box-shadow: 0px 1px 2px 0px #1018280d, 0px 14px 60px 0px #6941c633;
`;

const StyledBenefitsCardText = styled.div`
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large}px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.primary};
`;

const HomeBannerSection = () => {
  return (
    <StyledOuterContainer>
      <StyledInnerContainer>
        <StyledContentBox transparent center>
          <StyledTopLinkContainer>
            <span>Unlock Financial Success with Our Expertise</span>
            <Link href={ROUTES.optInGeneral}>
              <StyledIconContainer>
                <span>Learn more</span>
                <Icon
                  alias={ICONS_ALIASES.arrowRight}
                  color={theme.colors.indigo.primary}
                />
              </StyledIconContainer>
            </Link>
          </StyledTopLinkContainer>
          <StyledTitle>Building trust through accounting expertise</StyledTitle>
          <Link href={ROUTES.optInGeneral}>
            <StyledBottomLinkContainer>
              <span>Get Started</span>
              <StyledIconContainer>
                <Icon
                  alias={ICONS_ALIASES.arrowRight}
                  color={theme.colors.gray.primary}
                />
              </StyledIconContainer>
            </StyledBottomLinkContainer>
          </Link>
        </StyledContentBox>
        <StyledContentBox>
          <StyledText>Monthly Volume Graph</StyledText>
          <StyledImageContainer>
            <picture>
              <source
                srcSet={`${config.basePath}/images/line-and-bar-chart.svg`}
                type="image/svg+xml"
              />
              <StyledImg
                src={`${config.basePath}/images/line-and-bar-chart.svg`}
                alt=""
                width="100%"
              />
            </picture>
          </StyledImageContainer>
        </StyledContentBox>
        <StyledCircle
          desktop
          size={1796}
          top={-1150}
          left={`calc(50% - ${1796 / 2}px)`}
        />
        <StyledCircle
          mobile
          size={1796}
          top={-1300}
          left={`calc(50% - ${1796 / 2}px)`}
        />
        <StyledImageContainer
          desktop
          absolute
          top={50}
          left="calc(50% - 500px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={230}
          left="calc(50% - 600px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={410}
          left="calc(50% - 350px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={60}
          right="calc(50% - 650px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={140}
          right="calc(50% - 350px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={380}
          right="calc(50% - 380px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={690}
          left="calc(50% - 720px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/line-pattern.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/line-pattern.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          top={630}
          right="calc(50% - 720px)"
        >
          <picture>
            <source
              srcSet={`${config.basePath}/images/line-pattern.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/line-pattern.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          desktop
          absolute
          zIndex={1}
          top={720}
          right="calc(50% - 475px)"
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={`${config.basePath}/images/activity-gauge2.svg`} alt="" />
        </StyledImageContainer>
        <StyledImageContainer mobile absolute top={25} left="calc(5% + 0px)">
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile absolute top={250} left="calc(4% + 0px)">
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile absolute top={10} right="calc(6% + 0px)">
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile absolute top={310} right="calc(7% + 0px)">
          <picture>
            <source
              srcSet={`${config.basePath}/images/morning-star.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/morning-star.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile absolute top={475} right={-90}>
          <picture>
            <source
              srcSet={`${config.basePath}/images/line-pattern.svg`}
              type="image/svg+xml"
            />
            <StyledImg
              src={`${config.basePath}/images/line-pattern.svg`}
              alt=""
              width={223}
            />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile absolute top={475} left={-90}>
          <picture>
            <source
              srcSet={`${config.basePath}/images/line-pattern.svg`}
              type="image/svg+xml"
            />
            <StyledImg
              src={`${config.basePath}/images/line-pattern.svg`}
              alt=""
              width={223}
            />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer
          mobile
          absolute
          zIndex={1}
          top={490}
          right="calc(3% - 5px)"
        >
          <StyledImg
            src={`${config.basePath}/images/activity-gauge.svg`}
            alt=""
            width={123}
          />
        </StyledImageContainer>
        <StyledBenefitsOuterContainer>
          <StyledBenefitsLabel>Benefits</StyledBenefitsLabel>
          <StyledBenefitsTitle>
            Discover the benefits
            <br />
            of partnering with us
          </StyledBenefitsTitle>
          <StyledBenefitsInnerContainer>
            <StyledBenefitsCard>
              <StyledBenefitsCardIconContainer>
                <Icon
                  alias={ICONS_ALIASES.reward}
                  color={theme.colors.indigo.primary}
                />
              </StyledBenefitsCardIconContainer>
              <StyledBenefitsCardText>
                Certified and experienced professionals
              </StyledBenefitsCardText>
            </StyledBenefitsCard>
            <StyledBenefitsCard>
              <StyledBenefitsCardIconContainer>
                <Icon
                  alias={ICONS_ALIASES.setting}
                  color={theme.colors.indigo.primary}
                />
              </StyledBenefitsCardIconContainer>
              <StyledBenefitsCardText>
                Personalized solutions
              </StyledBenefitsCardText>
            </StyledBenefitsCard>
            <StyledBenefitsCard>
              <StyledBenefitsCardIconContainer>
                <Icon
                  alias={ICONS_ALIASES.smile}
                  color={theme.colors.indigo.primary}
                />
              </StyledBenefitsCardIconContainer>
              <StyledBenefitsCardText>
                Proven track record of client success
              </StyledBenefitsCardText>
            </StyledBenefitsCard>
            <StyledBenefitsCard>
              <StyledBenefitsCardIconContainer>
                <Icon
                  alias={ICONS_ALIASES.users}
                  color={theme.colors.indigo.primary}
                />
              </StyledBenefitsCardIconContainer>
              <StyledBenefitsCardText>
                Exceptional customer service
              </StyledBenefitsCardText>
            </StyledBenefitsCard>
          </StyledBenefitsInnerContainer>
        </StyledBenefitsOuterContainer>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default HomeBannerSection;
