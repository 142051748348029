import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { SECTION_ID } from '_constants/ui';
import { theme } from '_constants/theme';
import { FIELDS_KEYS } from '_constants/forms';

import { sendMessage } from '_redux/contactUs/slice';

import {
  selectResult,
  selectError,
  selectLoading,
} from '_redux/contactUs/selector';

import SendQuestionForm from '_components/composed/forms/SendQuestionForm';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${theme.spaces.large19X}px;

  position: relative;
  overflow: hidden;

  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.regular}px;
  padding-bottom: ${theme.spaces.regular}px;
  padding-left: ${theme.spaces.large6X + 2}px;
  padding-right: ${theme.spaces.large6X + 2}px;

  @media ${theme.devices.tablet} {
    flex-direction: column-reverse;
    gap: ${theme.spaces.large6X + 2}px;
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
    padding-top: ${theme.spaces.regular}px;
    padding-bottom: ${theme.spaces.large8X}px;
  }
`;

const StyledContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: ${theme.spaces.large19X}px;
  padding-bottom: ${theme.spaces.large15X}px;
  padding-left: ${theme.spaces.regular}px;
  padding-right: ${theme.spaces.regular}px;
  border: 1px solid ${theme.colors.indigo.light};
  border-radius: 10px;
  background: ${`linear-gradient(0deg, ${theme.colors.indigo.light}, 30%, ${theme.colors.indigo.primary})`};

  @media ${theme.devices.tablet} {
    padding-top: ${theme.spaces.large9X}px;
    padding-bottom: ${theme.spaces.large5X}px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: ${theme.spaces.large}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large13X}px;
  font-weight: 600;
  line-height: 61.6px;
  letter-spacing: -0.03em;
  text-align: center;
  color: ${theme.colors.white};

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.large8X}px;
    line-height: 39.6px;
  }
`;

const StyledText = styled.div`
  display: inline-block;
  padding-left: ${theme.spaces.regular}px;
  padding-right: ${theme.spaces.regular}px;
  margin-bottom: ${theme.spaces.large}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large}px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: center;
  color: ${theme.colors.white};

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.medium}px;
    line-height: 27px;
  }
`;

const ContactsSection = () => {
  const dispatch = useDispatch();
  const result = useSelector(selectResult);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);

  const handleSubmit = (values) => {
    if (values) {
      dispatch(
        sendMessage({
          category: 'ACCOUNTING',
          name: values[FIELDS_KEYS.fullName],
          email: values[FIELDS_KEYS.email],
          message: values[FIELDS_KEYS.message],
        })
      );
    }
  };

  return (
    <StyledOuterContainer id={SECTION_ID.contacts}>
      <StyledInnerContainer>
        <StyledContentBox>
          <StyledTitle>Have other questions?</StyledTitle>
          <StyledText>
            Our agents will get back to you within 24 hours.
          </StyledText>
          <SendQuestionForm
            error={error}
            success={result}
            submitDisabled={loading}
            onSubmit={handleSubmit}
          />
        </StyledContentBox>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default ContactsSection;
