import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Textarea from '_components/atomic/controls/Textarea';

const TextareaField = ({
  name,
  control,
  rules = {},
  feedback,
  ...restProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field,
        fieldState: { error },
        formState: { isSubmitted },
      }) => (
        <Textarea
          {...restProps}
          {...field}
          valid={isSubmitted && !error}
          invalid={isSubmitted && error}
          feedback={error?.message || feedback}
        />
      )}
    />
  );
};

TextareaField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  feedback: PropTypes.node,
};

export default TextareaField;
