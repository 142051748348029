import { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from '_hooks';
import { PRODUCT_CODES } from '_constants/products';

import { getPrices } from '_redux/variants/slice';
import { selectPriceMapByProductCode } from '_redux/variants/selector';

import HomeBannerSection from '_components/composed/sections/HomeBannerSection';
import AboutSection from '_components/composed/sections/AboutSection';
import SolutionsSection from '_components/composed/sections/SolutionsSection';
import ClientsSection from '_components/composed/sections/ClientsSection';
import GeneralFAQSection from '_components/composed/sections/GeneralFAQSection';
import ContactsSection from '_components/composed/sections/ContactsSection';

const StyledPageContainer = styled.div``;

const HomePage = () => {
  const router = useRouter();
  const { sectionId } = router.query;
  const prevSectionId = usePrevious(sectionId);
  const dispatch = useDispatch();

  const priceMapByProductCode = useSelector(
    selectPriceMapByProductCode(PRODUCT_CODES.accountingMonthlyBookkeeping)
  );

  useEffect(() => {
    if (prevSectionId !== sectionId && sectionId) {
      document.getElementById(sectionId)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [prevSectionId, sectionId]);

  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);

  return (
    <StyledPageContainer>
      <HomeBannerSection />
      <AboutSection />
      <SolutionsSection prices={priceMapByProductCode} />
      <ClientsSection />
      <GeneralFAQSection />
      <ContactsSection />
    </StyledPageContainer>
  );
};

export default HomePage;
