import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';

import { config } from '_config';
import { ROUTES } from '_constants/routes';
import { SECTION_ID } from '_constants/ui';
import { theme } from '_constants/theme';

import Button from '_components/atomic/Button';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${theme.spaces.large19X}px;

  position: relative;
  overflow: hidden;

  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.large10X}px;
  padding-bottom: ${theme.spaces.large15X}px;
  padding-left: ${theme.spaces.large28X - 1}px;
  padding-right: ${theme.spaces.large28X - 1}px;

  @media ${theme.devices.tablet} {
    flex-direction: column-reverse;
    gap: ${theme.spaces.large6X + 2}px;
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
    padding-top: ${theme.spaces.regular}px;
    padding-bottom: ${theme.spaces.large8X}px;
  }
`;

const StyledContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledImageContainer = styled(forwardRef(({ desktop, mobile, absolute, top, bottom, left, right, zIndex, ...props }, ref) => <div ref={ref} {...props} />))`
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
    `}

  ${({ top }) =>
    typeof top !== 'undefined' &&
    css`
      top: ${typeof top === 'string' ? top : `${top}px`};
    `}

  ${({ bottom }) =>
    typeof bottom !== 'undefined' &&
    css`
      bottom: ${typeof bottom === 'string' ? bottom : `${bottom}px`};
    `}

  ${({ left }) =>
    typeof left !== 'undefined' &&
    css`
      left: ${typeof left === 'string' ? left : `${left}px`};
    `}

  ${({ right }) =>
    typeof right !== 'undefined' &&
    css`
      right: ${typeof right === 'string' ? right : `${right}px`};
    `}

  ${({ zIndex }) =>
    typeof zIndex !== 'undefined' &&
    css`
      z-index: ${zIndex};
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${theme.devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}
  }
`;

const StyledLabel = styled.div`
  margin-bottom: ${theme.spaces.medium}px;
  padding-left: ${theme.spaces.regular - 2}px;
  padding-right: ${theme.spaces.regular - 2}px;
  padding-top: ${theme.spaces.smallest}px;
  padding-bottom: ${theme.spaces.smallest}px;

  border-radius: 66px;
  background: #f9f5ff;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};

  @media ${theme.devices.tablet} {
    width: 80%;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: ${theme.spaces.large}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large8X}px;
  font-weight: 600;
  line-height: 39.6px;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.large4X}px;
    line-height: 30.8px;
  }
`;

const StyledText = styled.div`
  margin-bottom: ${theme.spaces.large6X + 2}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large}px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.secondary};

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.medium}px;
    line-height: 27px;
  }
`;

// eslint-disable-next-line max-len
// eslint-disable-next-line prettier/prettier, react/display-name, @next/next/no-img-element, jsx-a11y/alt-text
const StyledImg = styled(forwardRef(({ height, width, ...props }, ref) => <img ref={ref} {...props} />))`
  ${({ height }) =>
    typeof height !== 'undefined' &&
    css`
      height: ${typeof height === 'string' ? height : `${height}px`};
    `}

  ${({ width }) =>
    typeof width !== 'undefined' &&
    css`
      width: ${typeof width === 'string' ? width : `${width}px`};
    `}
`;

const AboutSection = () => {
  return (
    <StyledOuterContainer id={SECTION_ID.about}>
      <StyledInnerContainer>
        <StyledImageContainer desktop absolute left={55} bottom={-15}>
          <picture>
            <source
              srcSet={`${config.basePath}/images/profits-bar-chart.svg`}
              type="image/svg+xml"
            />
            <img
              src={`${config.basePath}/images/profits-bar-chart.svg`}
              alt=""
            />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile absolute left={-14} bottom={12}>
          <picture>
            <source
              srcSet={`${config.basePath}/images/profits-bar-chart.svg`}
              type="image/svg+xml"
            />
            <StyledImg
              src={`${config.basePath}/images/profits-bar-chart.svg`}
              alt=""
              width={234}
            />
          </picture>
        </StyledImageContainer>
        <StyledContentBox>
          <StyledImageContainer desktop>
            <picture>
              <source
                srcSet={`${config.basePath}/images/who-we-are.png`}
                type="image/png"
              />
              <img src={`${config.basePath}/images/who-we-are.png`} alt="" />
            </picture>
          </StyledImageContainer>
          <StyledImageContainer mobile>
            <picture>
              <source
                srcSet={`${config.basePath}/images/who-we-are.png`}
                type="image/png"
              />
              <StyledImg
                src={`${config.basePath}/images/who-we-are.png`}
                alt=""
                width="100%"
              />
            </picture>
          </StyledImageContainer>
        </StyledContentBox>
        <StyledContentBox>
          <StyledLabel>
            Our journey at Monexa began with a common challenge faced
          </StyledLabel>
          <StyledTitle>
            We saw the need for a stress-free and cost-effective solution for
            managing finances
          </StyledTitle>
          <StyledText>
            Our mission is to provide small business owners with exactly that,
            driven by the experiences of those who found bookkeeping and tax
            preparation to be overwhelming and costly.
          </StyledText>
          <Link href={ROUTES.about}>
            <Button rounded>Who we are</Button>
          </Link>
        </StyledContentBox>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default AboutSection;
