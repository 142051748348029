import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { Col, Row, Form } from 'react-bootstrap';

import { theme } from '_constants/theme';
import { ROUTES } from '_constants/routes';
import { FIELDS_KEYS } from '_constants/forms';
import { getFieldProps } from '_helpers/forms/fields';
import { hexToRGBA } from '_helpers/theme';

import BaseField from '_components/atomic/fields/BaseField';
import TextareaField from '_components/atomic/fields/TextareaField';
import Button from '_components/atomic/Button';

const StyledContainer = styled(Form)`
  max-width: 570px;
  width: 100%;
  padding-top: ${theme.spaces.large + 2}px;

  button {
    width: 100%;
    margin-bottom: ${theme.spaces.regular}px;
    justify-content: center;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  a {
    color: ${theme.colors.white};
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledMessageContainer = styled(forwardRef(({ error, ...props }, ref) => <div ref={ref} {...props} />))`
  display: inline-flex;
  margin-bottom: ${theme.spaces.regular}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.white};

  ${({ error }) =>
    error &&
    css`
      color: ${theme.colors.red.primary};
    `}
`;

const SendQuestionForm = ({ error, success, submitDisabled, onSubmit }) => {
  const {
    control,
    formState: { isValidating },
    reset,
    handleSubmit,
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const [emailVerifying, setEmailVerifying] = useState(false);

  useEffect(() => {
    if (success) {
      reset();
    }
  }, [success, reset]);

  return (
    <StyledContainer noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12} md={6}>
          <BaseField
            {...getFieldProps(FIELDS_KEYS.fullName)}
            label={undefined}
            placeholder="Full name*"
            textColor={theme.colors.white}
            placeholderColor={hexToRGBA(theme.colors.white, 0.8)}
            borderColor={hexToRGBA(theme.colors.indigo.light, 0.3)}
            backgroundColor={hexToRGBA(theme.colors.white, 0.1)}
            control={control}
          />
        </Col>
        <Col xs={12} md={6}>
          <BaseField
            {...getFieldProps(FIELDS_KEYS.email, {
              validationOptions: { setProcessing: setEmailVerifying },
            })}
            label={undefined}
            placeholder="Email*"
            textColor={theme.colors.white}
            placeholderColor={hexToRGBA(theme.colors.white, 0.8)}
            borderColor={hexToRGBA(theme.colors.indigo.light, 0.3)}
            backgroundColor={hexToRGBA(theme.colors.white, 0.1)}
            control={control}
            loading={emailVerifying}
            feedback={emailVerifying ? 'Verifying...' : undefined}
          />
        </Col>
        <Col xs={12}>
          <TextareaField
            {...getFieldProps(FIELDS_KEYS.message)}
            label={undefined}
            textColor={theme.colors.white}
            placeholderColor={hexToRGBA(theme.colors.white, 0.8)}
            borderColor={hexToRGBA(theme.colors.indigo.light, 0.3)}
            backgroundColor={hexToRGBA(theme.colors.white, 0.1)}
            control={control}
          />
        </Col>
        <Col xs={12}>
          <StyledMessageContainer>
            We care about your data in our&nbsp;
            <Link href={ROUTES.privacy}>
              <span>privacy policy</span>
            </Link>
            .
          </StyledMessageContainer>
        </Col>
        <Col xs={12}>
          <Button
            type="submit"
            secondary
            disabled={emailVerifying || isValidating || submitDisabled}
          >
            Send question
          </Button>
        </Col>
      </Row>
      {success && (
        <StyledMessageContainer>
          Thank you for your question. Our agents will respond to your inquiry
          as quickly as possible.
        </StyledMessageContainer>
      )}
      {error && (
        <StyledMessageContainer error>{error?.message}</StyledMessageContainer>
      )}
    </StyledContainer>
  );
};

SendQuestionForm.propTypes = {
  error: PropTypes.object,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SendQuestionForm;
