import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import { config } from '_config';
import { SECTION_ID } from '_constants/ui';
import { theme } from '_constants/theme';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow: hidden;

  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.large15X}px;
  padding-bottom: ${theme.spaces.large15X}px;

  .swiper {
    max-width: 659px;
    width: 100%;

    .swiper-wrapper {
      .swiper-slide {
        width: auto;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        height: 5px;
        width: 30px;
        border-radius: 5px;
        background: #6941c666;

        &.swiper-pagination-bullet-active {
          background: ${theme.colors.indigo.primary};
        }
      }
    }
  }

  @media ${theme.devices.tablet} {
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
    padding-top: ${theme.spaces.large6X}px;
    padding-bottom: ${theme.spaces.large8X}px;
  }
`;

const StyledLabel = styled.div`
  padding-left: ${theme.spaces.regular - 2}px;
  padding-right: ${theme.spaces.regular - 2}px;
  padding-top: ${theme.spaces.smallest}px;
  padding-bottom: ${theme.spaces.smallest}px;

  border-radius: 66px;
  background: #f9f5ff;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};
`;

const StyledTitle = styled.div`
  padding-top: ${theme.spaces.medium}px;
  margin-bottom: ${theme.spaces.large6X + 2}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large8X}px;
  font-weight: 600;
  line-height: 39.6px;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    margin-bottom: ${theme.spaces.large2X + 2}px;
    font-size: ${theme.fontSizes.large4X}px;
    line-height: 30.8px;
    text-align: center;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledImageContainer = styled(forwardRef(({ desktop, mobile, ...props }, ref) => <div ref={ref} {...props} />))`
  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  @media ${theme.devices.tablet} {
    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `}

    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}
  }
`;

const StyledTestimonialContainer = styled.div`
  padding-top: ${theme.spaces.large6X + 2}px;
  padding-bottom: ${theme.spaces.large6X + 2}px;

  @media ${theme.devices.tablet} {
    padding-top: ${theme.spaces.large3X + 2}px;
    padding-bottom: ${theme.spaces.large3X + 2}px;
  }
`;

const StyledTestimonialComment = styled.div`
  margin-bottom: ${theme.spaces.large}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large}px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.03em;
  text-align: center;
  color: ${theme.colors.gray.primary};
`;

const StyledTestimonialClientName = styled.div`
  margin-bottom: ${theme.spaces.smallX - 2}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.medium}px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.03em;
  text-align: center;
  color: ${theme.colors.gray.primary};
`;

const StyledTestimonialClientTitle = styled.div`
  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: center;
  color: ${theme.colors.gray.secondary};
`;

// eslint-disable-next-line max-len
// eslint-disable-next-line prettier/prettier, react/display-name, @next/next/no-img-element, jsx-a11y/alt-text
const StyledImg = styled(forwardRef(({ height, width, ...props }, ref) => <img ref={ref} {...props} />))`
  ${({ height }) =>
    typeof height !== 'undefined' &&
    css`
      height: ${typeof height === 'string' ? height : `${height}px`};
    `}

  ${({ width }) =>
    typeof width !== 'undefined' &&
    css`
      width: ${typeof width === 'string' ? width : `${width}px`};
    `}
`;

const ClientsSection = () => {
  return (
    <StyledOuterContainer id={SECTION_ID.clients}>
      <StyledInnerContainer>
        <StyledLabel>Testimonials</StyledLabel>
        <StyledTitle>What our clients say</StyledTitle>
        <StyledImageContainer desktop>
          <picture>
            <source
              srcSet={`${config.basePath}/images/avatars-desktop.svg`}
              type="image/svg+xml"
            />
            <img src={`${config.basePath}/images/avatars-desktop.svg`} alt="" />
          </picture>
        </StyledImageContainer>
        <StyledImageContainer mobile>
          <picture>
            <source
              srcSet={`${config.basePath}/images/avatars-mobile.svg`}
              type="image/svg+xml"
            />
            <StyledImg
              src={`${config.basePath}/images/avatars-mobile.svg`}
              alt=""
              width="100%"
            />
          </picture>
        </StyledImageContainer>
        <Swiper
          grabCursor={true}
          modules={[Pagination]}
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <StyledTestimonialContainer>
              <StyledTestimonialComment>
                “As a busy professional, I needed a trustworthy partner to
                handle my personal finances. Monexa exceeded my expectations
                with their attention to detail and commitment to optimizing my
                financial situation. They made tax season a breeze, and I highly
                recommend their services.“
              </StyledTestimonialComment>
              <StyledTestimonialClientName>
                Cameron Williamson
              </StyledTestimonialClientName>
              <StyledTestimonialClientTitle>
                CEO of Trinix
              </StyledTestimonialClientTitle>
            </StyledTestimonialContainer>
          </SwiperSlide>
          <SwiperSlide>
            <StyledTestimonialContainer>
              <StyledTestimonialComment>
                “As a busy professional, I needed a trustworthy partner to
                handle my personal finances. Monexa exceeded my expectations
                with their attention to detail and commitment to optimizing my
                financial situation. They made tax season a breeze, and I highly
                recommend their services.“
              </StyledTestimonialComment>
              <StyledTestimonialClientName>
                Cameron Williamson
              </StyledTestimonialClientName>
              <StyledTestimonialClientTitle>
                CEO of Trinix
              </StyledTestimonialClientTitle>
            </StyledTestimonialContainer>
          </SwiperSlide>
          <SwiperSlide>
            <StyledTestimonialContainer>
              <StyledTestimonialComment>
                “As a busy professional, I needed a trustworthy partner to
                handle my personal finances. Monexa exceeded my expectations
                with their attention to detail and commitment to optimizing my
                financial situation. They made tax season a breeze, and I highly
                recommend their services.“
              </StyledTestimonialComment>
              <StyledTestimonialClientName>
                Cameron Williamson
              </StyledTestimonialClientName>
              <StyledTestimonialClientTitle>
                CEO of Trinix
              </StyledTestimonialClientTitle>
            </StyledTestimonialContainer>
          </SwiperSlide>
        </Swiper>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default ClientsSection;
